.accordion-panel {
	padding:0;
	width: 100%;
}

.Collapsible{
  width:100%;
}

.Collapsible__trigger {
	position: relative;
    justify-content: space-between;
    display: flex;
    color: #000;
    cursor: pointer;
    padding: 1%;
    margin: 16px 0;
    width: 98%;
    text-align: left;
    outline: none;
    font-size: 18px;
    transition: 0.4s;
    background: #fff;
    border-bottom: none;
    border: solid 2px #FB0000;
    border-radius: 10px;
}

.Collapsible__custom-sibling {
  font-size:14px;
	color:#000000;
	text-align:left;
	line-height:24px;
	padding: 0;
	border-radius:0 0 10px 10px;
	  }

.Collapsible__contentOuter{
	margin:-25px 0 0 0;
}

.Collapsible__custom-sibling p{
      padding: 25px;
	  background: #fff;;
        }	