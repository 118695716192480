@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");

.root-div {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    flex-direction: column;
    display: flex;
}

.sub-root-div12 {
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    margin-bottom: 16px;
}

.h1-style1 {
    font-weight: bold;
    font-size: 32px;
}

.link-color {
    color: #52a0fd;
    font-weight: bold;
    text-decoration: none;
}

.homepage-head{
    background-color: #000;
    color: #fff;
    grid-template-rows: auto auto;
    display: inline-block;
    padding: 30px;
    padding-right: 15%;
    padding-left: 15%;
    display: flex;
    overflow: hidden;
    justify-content: space-between;
}

.heading-data{
    font-size: 52px;
    margin-bottom: 30px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    letter-spacing: 0.03em;
    font-weight:bold;
}

.red-heading-data{
    color: red;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    letter-spacing: 0.03em;
    font-weight:bold;
}

.grid3213{
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 30px;
    width: 75%;
    margin-top: 50px;
    margin-bottom: 50px;
    justify-content: space-around;
}

.nft-button{
    background-color: green;
    color: white;
    padding: 10px 40px;
    padding-bottom: 15px;
    font-weight: bold;
    margin-top: 40px;
    align-self: flex-end;
    margin-right: 15%;
}

.absolute-id1{
    position: absolute;
    top: 20px;
    z-index: 10;
    left: 20px;
    background: #efefef;
    padding: 5px 15px;
    border-radius: 5px;
    opacity: 0.9;
}

.absolute-delete-button{
    position: absolute;
    top: 20px;
    width: 50px;
    z-index: 10;
    right: 20px;
    color: #fff;
    background: red;
    padding: 5px 15px;
    border-radius: 5px;
}
.absolute-delete-button2{
    position: absolute;
    top: 20px;
    width: 50px;
    z-index: 10;
    right: 80px;
    color: #fff;
    background: green;
    padding: 5px 15px;
    border-radius: 5px;
}

.grid-item{
    background-color: #eee;
    width:max-content;
    text-align:center;
    font-size: 18px;
    font-weight: bold;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
}

.head-para{
    font-size: 18px;
    margin-bottom: 15px;
    color: #ccc;
}

.header-button{
    background-color: greenyellow;
    padding: 10px 30px;
    border-radius: 2px;
    font-weight: bold;
}

.container-root {
    align-items: flex-end;
    height: 380px;
    position: relative;
    margin-right: 35px;
    background-color: #52a0fd;
}

.search-form{
    font-size: 18px;
    font-weight: bold;
    margin-top: 5px;
}

.visually-hidden{
    margin-right: 20px;
    letter-spacing: 0.08em;
}

.search-box{
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 10px;
    background-color: #fff;
    justify-content: baseline;
}

.visually-hidden-text{
    padding-right: 15px;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 16px;
}

.visually-hidden-button{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 24px;
    padding-bottom: 8px;
}

.filter-icon-css1{
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 100px;
}

.grid-col{
    margin-top: 80px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: row;
    width: 65%;
    justify-content: space-around;
}

.grid-col1{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.grid-col-item{
    background-color: #000;
    color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 50px;
    border-radius: 10px;
    width: 350px;
}

.grid-col-item1{
    background-color: #000;
    color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px;
    margin: 15px;
    border-radius: 10px;
    width: 250px;
}

.admin--grid-text{
    margin-top: 20px;
    font-size: 32px;
    font-weight: bold;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

@media (max-width: 1440px) {
    .grid3213{
        grid-template-columns: auto auto auto;
    }
}

@media (max-width: 1100px) {
    .grid3213{
        grid-template-columns: auto auto;
    }
}

@media (max-width: 700px) {
    .grid3213{
        grid-template-columns: auto;
    }
}