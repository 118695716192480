.App {
    display: flex;
    text-align: center;
    padding:0 5px;
    border: solid 1px #FB0000;
    border-radius: 10px;
    width: 50%;
    margin: 10px auto;

  }

  .app-inner{
    width: auto;
    margin: auto !important;
    display: flex;
  }

  .App svg{
    display: none;
  }

  .App div{
      color: #ffffff !important;
    margin:0 5px 0 1px;
  }
  
  .time {
    font-size: 35px;
  }
  