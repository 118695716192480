@font-face {
    font-family: Big John;
    src:url(../../assets/fonts/BIG-JOHN.otf);
}

html{
    scroll-behavior: smooth;
}

body{
    font-family: 'Big John', sans-serif !important;
    margin: 0;
    padding: 0;
}

/* Banner Section */
.first-section{
    background-image: url(../../assets/images/banner-background.png);
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    margin: 0;
    padding: 0;
    float: left;
}


.container{
    width: 1300px;
    margin: auto;
 }

.header{
    float: left;
    width: 100%;
}

.logo {
    width: 147px;
    float: left;
    margin: 20px 0 0 0;
}

.navigation-menu{
	width: 88%;
    float: right;
    text-align: right;
    margin: 40px 0 0 0;
    position: relative;
}

.navigation-menu ul {
    list-style: none;
    display: flex;
    float: right;
    padding: 0;
    margin: 0;
}

.navigation-menu ul li a{
    color: #fff;
    text-decoration: none;
	font-size:18px;
	padding-left: 18px;
    line-height: 46px;
}

.navigation-menu ul li a:hover{
    color: #F1C92A !important;
}

.navigation-menu ul li .active{
    color: #F1C92A !important;
}

.navigation-menu .opensea{
    margin: -6px 0 0 0;
}

.navigation-menu ul li .join-button {
    padding: 13px 25px;
    font-size: 20px;
    border-radius: 10px;
    background-image: linear-gradient(#FBE58D, #C19127);
    color: #000;
    cursor: pointer;
	box-shadow:0 3px 5px;
    line-height: 36px;
    margin-left: 19px;
} 

.navigation-menu ul li .join-button-disable {
    padding: 13px 25px;
    font-size: 20px;
    border-radius: 10px;
    background-image: linear-gradient(#fafafa, #b0b0b0);
    color: #000;
    cursor:none;
	box-shadow:0 3px 5px;
    line-height: 36px;
    margin-left: 19px;
}

.navigation-menu ul li .join-button-disable:hover {
    background-image: linear-gradient(#fafafa, #b0b0b0) !important;
    color: #000 !important;
}

.navigation-menu ul li .join-button:hover {
    background-image: linear-gradient(#C19127, #FBE58D) !important;
    color: #000 !important;
}

.navigation-menu ul li .join-button-bottom {
    display: block;
    text-align: center;
    font-size: 9px;
    position: relative;
    bottom: 8px;
}

.navigation-menu .btn-error{
    font-size: 12px;
    line-height: 40px;
}

.mobile-nft{
    display:none;
}

.banner-text{
	float:left;
	width:100%;
	margin:50px 0 0 0;
	padding:0;
}

.banner-title {
    float: left;
    width: 50%;
    margin-top:120px;
}

.banner-title p {
    color: #fff;
    font-size: 20px;
    line-height: 32px;
}

.banner-image {
    float: right;
    width: 50%;
	text-align:right;
	margin:0 0 -5px 0;
}


/* Mint Section */
.second-section {
	width: 100%;
	background:#0E032B;
	margin: 0;
	padding:100px 0;
	float: left;
   }
   
.mint-image{
	float:left;
	width:45%;
	margin:0;
	padding:0;
}
    
.mint-text{
	float: right;
	width: 53%;
	color: #fff;
	text-align: center;
}

.mint-text h5 {
    font-size: 20px;
    color: #F1C92A;
	margin:0;
	padding:0;
}

.mint-text h4 {
    font-size: 48px;
    color: #F1C92A;
	margin:0;
	padding:0;
    line-height: 50px;
}

.mint-text h2 {
    font-size: 80px;
    color: #FB0000;
    letter-spacing: -5px;
	margin:0;
	padding:0;
    line-height: 90px;
}

.mint-text p {
	font-size: 18px;
	line-height:26px;
	margin:0;
	padding:0;
    }

.mint-bar{
	width:90%;
	float:left;
	padding: 20px 20px 10px 20px;
	background:#fff;
	border-radius:10px;
	margin:10px 0;
}

.number{
    float:left;
    width: 40%;
    text-align: left;
}

.number span {cursor:pointer; }

.minus, .plus{
	display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 40px;
    color: #000;
    line-height: 60px;
}

.number input{
    width: 60px;
	line-height: 50px;
    text-align: center;
    font-size: 48px;
    border: none;
    display: inline-block;
    vertical-align: middle;
	font-family: 'Big John', sans-serif;
}    

.max-number{
	float:right;
	width:30%;
	text-align:right;
	font-size:30px;
	color:#000;	
	line-height: 60px;
}

.mint-bar-1{
	width:50%;
	padding: 10px;
	border:solid 1px #F1C92A;
	border-radius:10px;
	margin:10px auto;
}

.number-1{
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: #F1C92A;
    line-height: 36px;
    -webkit-animation: flash linear 1s infinite;
      animation: flash linear 1s infinite;
}

 @-webkit-keyframes flash {
      0% { opacity: 1; } 
      50% { opacity: .1; } 
      100% { opacity: 1; }
  }
  @keyframes flash {
      0% { opacity: 1; } 
      50% { opacity: .1; } 
      100% { opacity: 1; }
  }


.mint-button {
    padding: 10px 80px;
	font-family: 'Big John', sans-serif;
    font-size: 40px;
    background-color: #FE0000;
    border-radius: 6px;
    margin: 10px 0 0 0;
    background-image: linear-gradient(#FE0000, #A40404);
    border: none;
    color: #fff;
    cursor: pointer;
} 

.mint-button-disable {
    padding: 10px 80px;
	font-family: 'Big John', sans-serif;
    font-size: 40px;
    background-color: #FE0000;
    border-radius: 6px;
    margin: 10px 0 0 0;
    background-image: linear-gradient(#fafafa, #b0b0b0);
    border: none;
    color: #000;
    cursor:none;
}

.mint-button-disable:hover{
    background-image: linear-gradient(#fafafa, #b0b0b0);
}

.mint-button:hover{
    background-image: linear-gradient(#A40404, #FE0000);
} 

.metamask-modal{
    text-align: center;
}

.metamask-modal img{
    width: 50%;
}

.metamask-modal button{
    background-image: linear-gradient(#FBE58D, #C19127);
    padding: 10px 20px;
    font-size: 20px;
    border-radius: 10px;
    border: none;
    color: #000;
    cursor: pointer;
    line-height: 28px;
    font-family: 'Big John';
    margin-top: 20px;
}



/* About Section */
.third-section {
	width: 100%;
	background:#0E032B;
	margin: 0;
	padding:50px 0;
	float: left;
    min-height: 500px;
   }

.third-section h2{
    font-size: 70px;
    color: #FB0000;
    text-align: center;
	margin:0;
	padding:0
}

.third-section p{
    font-size: 22px;
    text-align: left;
	line-height:30px;
	color:#fff;
}

.third-section span{
    font-size: 22px;
    text-align: center;
	line-height:30px;
	color:#F1C92A;
	display:block;
	width:100%;
}

.third-section h5 {
    font-size: 60px;
    color: #F1C92A;
    margin: 0;
    padding: 0;
    text-align: center;
    line-height:120px;
}

.third-section h6 {
    font-size: 30px;
    color: #F1C92A;
    margin: 0;
    padding: 0;
}

.third-section ul li {
	font-size: 22px;
	line-height:30px;
	color:#fff;
	padding:10px;
	list-style-image:url(../../assets/images/basketball.png);
}

.gold_section {
    width: 100%;
    margin: 50px 0;
    display: flex;
}

.gold_section img {
    width: 23%;
    border-radius: 30px;
    margin-right:25px;
}




/* Grass Section 1 */
.grass-section1{
	width:100%;
	background:#0E032B url(../../assets/images/yellow-grass.png) repeat-x;
	height:200px;
	float:left;
}


/* Collection Section */
.fourth-section {
	width: 100%;
	background:#FB0000;
	margin: 0;
	padding:60px 0;
	float: left;
	border-bottom:solid 1px #D50000;
   }
   
.fourth-section h2{
    font-size: 70px;
    color: #0E032B;
    text-align: center;
	margin:0;
	padding:0
}

.fourth-section span{
    font-size: 20px;
    color: #F1C92A;
	line-height:50px;
    text-align: center;
	display: block;
	margin:0;
	padding:0
}

.fourth-section .slider {
	width: 100%;
	float: left;
	margin:50px 0 0 0;
   
   }

.fourth-section .slider .carousel-container ul li{
    width:385px !important;
    margin: 0 24px !important;
}

.fourth-section .slider .carousel-container ul li img{
    width:100% !important;
}

.react-multiple-carousel__arrow{
    background: #0E032B !important;
}

.react-multiple-carousel__arrow:hover {
    background:#F1C92A !important;
}

.react-multiple-carousel__arrow--right {
    right: 0 !important;
}

.react-multiple-carousel__arrow--left {
    left: 0 !important;
}
   
 
/* Roadmap Section */
.fifth-section {
	width: 100%;
	background:#FB0000;
	margin: 0;
	padding:60px 0;
	float: left;
   }
   
.fifth-section h2{
    font-size: 70px;
    color: #0E032B;
    text-align: center;
	margin:0;
	padding:0
}

.road-map{
	float:left;
	width:100%;
	margin:80px 0 0 0;
}

.box-1{
    width: 43%;
    float: left;
}

.box-2{
    width: 176px;
    float: left;
    margin: 0;
}

.box-3{
    width: 43%;
    float: left;
}

.t-camp { 
    background-color: #0E032B;
    border-radius: 9px; 
    padding: 20px;
}

.t-camp h5{
    font-size: 26px;
    color: #ffff;
    margin:0 0 10px 0;
    font-family: 'Big John', sans-serif;
}

.t-camp ul{
    margin:0;
	padding: 0 0 0 20px;
}

.t-camp ul li{
    margin:0;
    color: #ffff;
    font-size: 18px;
    line-height: 24px;
	font-family: 'Josefin Sans', sans-serif;
	list-style-image: url(../../assets/images/basketball-small.png);
	padding:5px 0;
}

.public-kickoff{
    margin-top: 65px ;
}

.game-begin{
    margin-top: 85px ;
}

.donation{
    margin-top: 115px ;
}

.poppin-champagne{
    margin-top: 85px ;
}

.t-camp2 {
    margin-top: 140px ;
}

.real-mvp{
    margin-top: 120px ;
}

.halftime-festivities{
    margin-top: 100px ;
}

.fourth-quarter{
    margin-top: 85px ;
}

.road-map-mobile{
	float:left;
	width:100%;
	margin:50px 0 0 0;
    display: none;
}

.box-4{
    width: 100%;
    float: left;
}

.box-4 .public-kickoff,.box-4 .game-begin,.box-4 .donation,.box-4 .poppin-champagne, .box-4 .t-camp2,.box-4 .real-mvp,.box-4 .halftime-festivities,.box-4 .fourth-quarter {
    margin-top: 20px;
}


/* Grass Section 2 */
.grass-section2{
	width:100%;
	background:#FB0000 url(../../assets/images/blue-grass.png) repeat-x;
	height:200px;
	float:left;
}


/* FAQ Section */
.sixth-section {
	width: 100%;
	background:#0E032B;
	margin: 0;
	padding:60px 0;
	float: left;
   }
   
.sixth-section h2{
    font-size: 70px;
    color:#FB0000;
    text-align: center;
	margin:0;
	padding:0
}

.sixth-section .panel {
	width: 100%;
	float: left;
	margin:30px 0 0 0;
   }

.team_section {
    float: left;
    width: 100%;
    background-color: #0E032B;
    padding:60px 0 0 0;
}

.team_section h2 {
    font-size: 70px;
    color: #FB0000;
    text-align: center;
    margin: 0;
    padding: 0;
}

.team_section span {
    font-size: 20px;
    color: #F1C92A;
    line-height: 26px;
    text-align: center;
    display: block;
    margin: 0;
    padding: 10px;
}

.team-bottom-panel {
    float: left;
    width: 100%;
    margin: 40px 0 0 0;
    padding: 0;
}

.team-block {
    float: left;
    width: 16%;
    position: relative;
    padding: 0 2%;
    text-align: center;
}

.team-block  h4 {
    font-size: 40px;
    color: #FB0000;
    text-align: center;
    padding: 0;
    margin: 10px 0;
    line-height: 40px;
}

.team-block h6 {
    color:#F1C92A;
    padding: 0;
    margin: 0;
    font-size: 18px;
    text-align: center;
    line-height: 20px;
}

.follow-button{
    margin: 10px 0;
}

.team-block a{
    font-size:12px;
    border: solid 1px #fff;
    text-decoration: none;
    padding: 5px 10px;
    color: #fff;
    border-radius: 5px;
}

.team-block a:hover{
    border: solid 1px #FB0000;
    color: #FB0000;
}

.team-block p{
    font-size: 16px;
    color: #fff;
    font-family: 'Josefin Sans', sans-serif;
    line-height: 26px;
    text-align: center;
    margin: 0;
    padding:10px 0;
}
   
/* Footer Section */
.footer-section {
	width: 100%;
	background:#0E032B;
	margin: 0;
	float: left;
   }

.footer-panel{
   float:left;
   width:100%;
   margin:0;
   padding:0;
}

.footer-panel h4 {
    font-size: 36px;
    color: #FB0000;
	margin:0;
	padding:0;
}

.footer-panel h4 span {
    color: #fff;
}

.footer-panel p {
    font-size: 14px;
    color: #fff;
	padding:10px 0 20px 0;
	margin:0;
}

.footer-panel .join-button {
    padding: 10px 25px;
    font-size: 24px;
    border-radius: 10px;
    background-image: linear-gradient(#FE0000, #A40404);
    color: #000;
    cursor: pointer;
	box-shadow:0 3px 5px;
	text-decoration:none;
}

.footer-panel .join-button:hover {
    background-image: linear-gradient(#A40404, #FE0000);
    color: #000;
}

.footer-links{
    float: left;
    width:60%;
	margin:10px 0;
}

.footer-links ul{
    list-style: none;
	display:flex;
	padding:0;	
}

.footer-links ul li{
    padding:0 20px;
	border-right:solid 2px #fff;
}

.footer-links ul li a {
    font-size: 14px;
    color:#fff;
	text-decoration:none;
}

.footer-links ul li:first-child {
    padding:0 20px 0 0;
}


.footer-links ul li:last-child {
    border-right:none;
}

.social-icons {
	float: right;
    width:30%;
	text-align:right;
	margin:20px 0 0 0;
}

.social-icons img{
	padding: 0 5px;
}

/* Congrats Pop up */

.congrats-popup{
    width: 500px;
    height: 500px;
    background: #1f050e;
    border-radius: 20px;
    text-align: center;
}

.congrats-popup img{
 border-radius: 20px 20px 0 0;
}

.congrats-popup p{
    color: #fff;
    text-align: center;
    padding: 0 20px;
    margin: 0;
   }

.congrats-popup button{
    padding: 10px 40px;
    font-family: 'Big John', sans-serif;
    font-size: 20px;
    background-color: #FE0000;
    border-radius: 6px;
    margin: 20px 0 0 0;
    background-image: linear-gradient(#FE0000, #A40404);
    border: none;
    color: #fff;
    cursor: pointer
   }

.congrats-popup button:hover{
    background-image: linear-gradient(#A40404, #FE0000);
    color: #fff;
   }

.ReactModal__Content{ 
    border:none !important;
    border-radius: 25px !important;
    box-shadow: 0px 10px 30px #818181;
}

.waiting-overlay{
    position: fixed; 
    width: 100%; 
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.80); 
    z-index: 2; 
    cursor: pointer; 
}

  .waiting-overlay #text{
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;
    color: white;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    background: url(../../assets/images/loadingGoat.png) no-repeat center;
  }

  .waiting-overlay .loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #ff0000; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


/* NFT Page */

.nft-section{
    float: left;
    width: 100%;
    margin: 50px 0 0 0;
}

.nft-section h3{
    float: left;
    width: 100%;
    text-align: center;
    margin: 100px 0;
    color: #fff;
    font-size: 30px;
}

.nft-section h3 a{
    color: #ff0000;

}

.filters{
    float: left;
    width: 100%;
    margin: 10px 0;
}

.nft-search{
    float: left;
    width: 30%;
    padding: 10px;
    font-size:20px;
    border-radius: 10px;
    outline: 2px solid #FB0000;
    border: none;
    font-family: 'Big John', sans-serif !important;
}

.nft-order{
    float: right;
    width: 30%;
    padding: 10px;
    font-size:20px;
    border-radius: 10px;
    outline: 2px solid #FB0000;
    border: none;
    font-family: 'Big John', sans-serif !important;
}

.nft-card{
    width: 27%;
    float: left;
    margin: 2%;
    text-align: center;
    background:#F1C92A;
    border-radius: 20px;
    padding: 10px;
}

.nft-card img{
    max-width: 360px;
    max-height: 360px;
    height: 100%;
    width: 100%;
    border-radius: 10px;
}

.nft-card h4{
    color: #0E032B;
    font-size:24px;
    margin: 10px 0;
    padding: 0;
}

.nft-card h6{
    color: #ff0000;
    font-size:20px;
    margin: 0 0 10px 0;
    padding: 0;
}

button.buy-btn {
    padding: 10px 37px;
    font-size: 22px;
    border-radius: 10px;
    background-image: linear-gradient(#FE0000, #A40404);
    color: #000;
    cursor: pointer;
    font-family: 'Big John';
    text-decoration: none;
    background-color: #A40404;
    color:#fff;
    border: none;
    outline: 0;
}

.infotext_Modal {
    text-align: center;
}

.infotext_Modal img {
    width: 20%;
}

.infotext_Modal h4 {
    font-size: 20px;
    font-family: 'Big John';
    font-weight: 300;
    position: relative;
    bottom: 19px;
    color: #343131;
}

.infotext_Modal p {
    font-size: 12px;
    text-transform: lowercase !important;
    position: relative;
    bottom: 28px;
    line-height: 10px;
    color: #8080809e;
}

button.infobtnModal  {
    padding: 10px 25px;
    border: 1px solid #FBE58D;
    background-image: linear-gradient(#FBE58D, #C19127);
    color: #000;
    font-size: 18px;
    border-radius: 7px;
    text-align: center;
    font-family: 'Big John', sans-serif;
    cursor: pointer;
}

button.nft_cardbtn1 {
    background-image: linear-gradient(#FE0000, #A40404);
    padding: 12px 20px;
    border: 1px solid #FE0000;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    background-color: #FE0000;
    font-family: 'Big John', sans-serif;
    border-radius: 5px;
}

button.nft_cardbtn1:hover {
    background-image: linear-gradient(#A40404, #FE0000);
    color: #fff;
}

.nft-card button[disabled=disabled], button:disabled{
    background-image: linear-gradient(#939393, #f4f4f4);
    padding: 12px 20px;
    border: 1px solid #a0a0a0;
    color: #000;
    font-size: 22px;
    cursor: none;
    font-family: 'Big John', sans-serif;
    border-radius: 5px;
}

i.fa.fa-window-close {
    float: right;
    /* margin-top: -29px; */
    position: relative;
    bottom: 28px;
    font-size: 24px;
    color: #A40404;
}

.priceModal h2 {
    font-size: 23px;
    padding-top: 27px;
    text-align: center;
    font-family: 'Big John';
    color: #000 !important;
}

input#price {
    border-radius: 5px;
    padding: 20px;
    font-family: 'Big John';
    border: 1px solid #80808061 !important;
    float:left;
    width:87%;
}

span.priceETh {
    position: absolute;
    right: 63px;
    background-color: #FE0000;
    padding: 19px 10px;
    color: #fff;
    font-size: 17px;
    font-family: 'Big John';
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-image: linear-gradient(#FE0000, #A40404);
}

button.priceModalbtn {
    background-image: linear-gradient(#FBE58D, #C19127);
    padding: 10px 20px;
    font-size: 20px;
    border-radius: 10px;
    color: #000;
    cursor: pointer;
    /* box-shadow: 0 3px 5px; */
    line-height: 28px;
    border: 1px solid #FBE58D;
    font-family: 'Big John';
    margin-top: 20px;
}

.priceModal {
    text-align: center;
    background: #fff !important;
    padding: 50px;
    border-radius: 20px;
}

.mint-image img {
    border-radius: 28px;
}

.nft_action {
    display: flex;
    float: right;
    padding: 10px
}
.del_nft {
    margin-right: 27px;
    font-size: 20px;
}
.edit_nft {
    margin-right: 29px;
    font-size: 20px;
}

ul.react-multi-carousel-track li img {
    border-radius: 30px;
}


/*  Responsive  */
@media screen and (min-device-width: 300px) and (max-device-width: 768px) {
.container {
    width: 95%;
}

.navigation-menu {
    width: 55%;
    float: right;
}

.navigation-menu ul li {
    display:none;
}

.navigation-menu ul li:last-child {
    display:block;
}

.mobile-nft{
    display:block;
    float: right;
    text-align: right;
    margin: 10px 5px 0 0;
    width: 50%;
    color: #fff;
    font-size: 14px;
}

.mobile-nft a{
    color: #fff;
    text-decoration: none;;
}

.banner-title {
    width: 100%;
    margin-top: 60px;
}

.banner-title img {
    width: 100%;
}

.banner-title p {
    font-size: 15px;
    line-height: 26px;
	text-align: center;
}

.banner-image {
    float: left;
    width: 100%;
    text-align: center;
}

.banner-image img {
    width: 100%;
}

.second-section {
    padding: 20px 0;
}

.mint-image {
    width: 100%;
}

.mint-image img{
    width: 100%;
}

.mint-text h5 {
    margin: 10px 0 0 0;
}

.App {
    width: 90% !important;
}

.mint-text {
    width: 100%;
}

.mint-text h4 {
    font-size: 40px;
}

.mint-text h2 {
    font-size: 50px;
    line-height: 60px;
}

.max-number {
    width: 50%;
}

.third-section h2 {
    font-size: 50px;
}

.third-section p {
    font-size: 18px;
}

.third-section span {
    font-size: 18px;
}

.third-section ul li {
    font-size: 18px;
  }

.third-section h5 {
   line-height: 60px;
}

.gold_section {
     display: block;
     text-align: center;
}

.gold_section img {
    width: 80%;
    margin-bottom: 25px;
}

.fourth-section h2 {
    font-size: 50px;
}

.fourth-section span {
    font-size: 16px;
    line-height: 26px;
}

.fourth-section .slider .carousel-container ul li {
    width: 347px !important;
}

.fifth-section h2 {
    font-size: 50px;
}

.road-map {
    display:none;
}

.road-map-mobile{
    display:block;
}

.team-block {
    width: 98%;
    text-align: center;
}

.sixth-section h2 {
    font-size: 50px;
}

.footer-panel {
    text-align: center;
}

.footer-links {
    width: 100%;
}

.footer-links ul {
    display: block;
    text-align: center;
}

.footer-links ul li:first-child {
    padding: 0 20px;
}

.footer-links ul li {
    border-right: none;
}

.social-icons {
    width: 100%;
    text-align: center;
	margin:20px 0;
}

.nft-search {
    float: left;
    width: 100%;
    margin: 0 0 10px 0;
}

.nft-order {
    float: left;
    width: 100%;
}

.nft-card {
    width: 92%;
}
	
}

@media screen and (min-device-width: 769px) and (max-device-width: 1200px) {
.container {
    width: 95%;
}

.navigation-menu {
    width: 55%;
    float: right;
}

.navigation-menu ul li {
    display:none;
}

.navigation-menu ul li:last-child {
    display:block;
}

.mobile-nft{
    display:block;
    float: right;
    text-align: right;
    margin-right: 20px;
    width: 50%;
    color: #fff;
}

.mobile-nft a{
    color: #fff;
    text-decoration: none;;
}

.banner-title img {
    width: 100%;
	margin-top: 75px;
}

.banner-image img {
    width: 100%;
}

.mint-image img {
    width: 100%;
}

.fourth-section .slider .carousel-container ul li {
    width: 332px !important;
}

.footer-links {
    width: 75%;
}

.social-icons {
    width: 24%;
}

.road-map {
    display:none;
}

.road-map-mobile{
    display:block;
}

.team-block {
    width: 42%;
    text-align: center;
}

}
