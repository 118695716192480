
.das_header {
    float: left;
    width: 100%;
    background-image: url(../../assets/images/banner-background.png);
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    padding: 0;

}
.logo_dash {
    float: left;
    width: 30%;
}
.user_prof {
    float: right;
}
.logo_dash img {
    width: 35%;
    margin: 19px 0px 10px 47px
}
.user_prof img {
    width: 9%;
    float: right;
    margin: 30px 46px 0px 0px;
    border-radius: 50px;
}

/* Dropdown */

.dropdown {
  display: inline-block;
  position: relative;
}

.dd-button {
  display: inline-block;
  border: 2px solid #FB0000;
  border-radius: 9px;
  padding: 10px 67px 10px 21px;
  background-color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
}

.dd-button:after {
  content: '';
  position: absolute;
  top: 32%;
  right: 15px;
  transform: translateY(-50%);
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
}

.dd-button:hover {
  background-color: #fff;
}


.dd-input {
  display: none;
}

.dd-menu {
  position: absolute;
  top: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0;
  margin: 2px 0 0 0;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.1);
  background-color: #ffffff;
  list-style-type: none;
}

.dd-input + .dd-menu {
  display: none;
} 

.dd-input:checked + .dd-menu {
  display: block;
} 

.dd-menu li {
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
}

.dd-menu li:hover {
  background-color: #f6f6f6;
}

.dd-menu li a {
  display: block;
  margin: -10px -20px;
  padding: 10px 20px;
}

.dd-menu li.divider{
  padding: 0;
  border-bottom: 1px solid #cccccc;
}

/* content-section */ 

.content-section {
    float: left;
    width: 100%;
}

.content-section {
    float: left;
    width: 100%;
}

.left_bar_text {
    float: left;
    width: 15%;
    background-color: #0e032b;
    height: 100vh;
}

.left_bar_text ul {
    padding: 0;
    margin: 0;
}

.left_bar_text ul li{
    padding: 0;
    margin: 0;
}

.left_bar_text ul li a{
    display:block;
    padding: 10px;
    color: #fff;
    text-decoration: none;
    font-size: 15px;
}

ul.bar_item li i {
    margin-right: 16px;
}
ul.bar_item li .active {
    background-color:#fff;
    color: #FB0000;
}
/* ul.bar_item li .active:hover {
    background-color: #fff;
    padding: 11px;
    color: #000;
}
ul.bar_item li a:hover {
    color: red;
} */
.left_bar_text img {
    margin-left: 36px;
    padding-top: 22px;
}
.adminNftContainer div {
   column-count: 3;
}





.admin-working-panel{
    float: right;
    width: 83%;
    height: 100vh;
    overflow: scroll;
    padding: 0 1%;
}
.right_bar_content div {
    display: flex;
    justify-content: space-between;
}
.sort_btn_text {
    margin: 26px 0px 0px 59px;
}
.btn_info {
    margin-right: 100px;
    margin-top: 24px;
}
a.btn_2 {
    border: 2px solid #FB0000;
    text-decoration: none;
    font-size: 17px;
    border-radius: 9px;
    color: #fff;
    width: 184px;
    height: 31px;
    text-align: center;
    padding-top: 13px;
    background-color: red;
}
.right_bar_content {
    border-bottom: 1px solid #000;
    height: 90px;
}
.filters {
    margin: 10px 0;
}
.filters, .nft-section {
    float: left;
    width: 100%;
}
.nft-search_1 {
    float: left;
    width: 68%;
    padding: 10px;
    font-size: 17px;
    border-radius: 9px;
    outline: 2px solid #FB0000;
    border: none;
    font-family: 'Big John', sans-serif !important;
    position: relative;
    bottom: 11px;
}
.fa-search:before {
    content: "\f002";
    position: relative;
    right: 113px !important;
    font-size: 20px !important;
    bottom: 2px;

}
.nft-order_1{
    width: 46%;
    padding: 10px;
    font-size: 17px;
    height: 41px;
    border-radius: 10px;
    /* outline: 2px solid #fb0000; */
    border: 2px solid #fb0000;
    font-family: "Big John",sans-serif!important;
    position: relative;
    right: 59px;
}

.giveaways{
    width: 50%;
    border: solid 1px #ddd;
    margin: 50px auto;
    text-align: center;
    padding: 5%;
    border-radius: 20px;
}

.giveaways span{
    color: #fb0000;
}

.giveaways input[type=text]{
    padding: 2%;
    border: solid 1px #ddd;
    float: left;
    width: 98%;
    margin: 10px 0;
    font-family: "Big John",sans-serif!important;
    font-size: 20px;
    border-radius: 10px;
}

.giveaways button{
    padding: 10px 50px;
    font-family: 'Big John', sans-serif;
    font-size: 30px;
    background-color: #FE0000;
    border-radius: 6px;
    margin: 10px 0 0 0;
    background-image: linear-gradient(#FE0000, #A40404);
    border: none;
    color: #fff;
    cursor: pointer;
}